.loginHeader {
  margin: 20px 0 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginHeader div {
  margin-left: auto;
}

.loginHeader > img {
  width: 200px;
}
