.rectangle {
    margin-right: 15px;
    margin-bottom: 5px;
    border: 3px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 26px;
    padding: 6px 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    }

.rectangle:hover {
    background-color: #07bf71;
    color: white;
    border: 3px solid white;
}

.clear-icon {
    margin-left: 10px;
    color: #C4C4C4;
    border: 2px solid #C4C4C4;
    border-radius: 25px;
    cursor: pointer;
    width: 25px;
}

.rectangle:hover .clear-icon {
    border: 2px solid white;
}