.main-container {
    position: relative;
    display: flex;
    height: 80%;
}
.main-container_create-button {
    position: absolute;
    right: 0;
    top: 20px;
}
.main-container_modal__paragraph {
    font-family: TT-Norms-Regular, sans-serif;
    width: 70%;
    text-align: center;
    align-self: center;
    margin-bottom: 50px;
    white-space: pre-wrap;
}
.main-container_modal__buttons-section {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
}