.table-header {
    display: grid;
    align-items: center;
    width: 1000px;
    height: 60px;
    transition: var(--default-transition);
}
.table-header h3 {
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    margin-bottom: 0;
    font-weight: 700;
}
.table-row {
    display: grid;
    border: solid 1px;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin-bottom: 15px;
    border-radius: 14px;
    height: 62px;
    list-style-type: none;
    transition: var(--default-transition);
}
.table-row h3 {
    font-family: TT-Norms-Regular, sans-serif;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px;
    margin-bottom: 0;
}
.table-row span {
    padding: 0;
    margin: 0;
    height: 60px;
}
.actions-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-right: 30px;
}
.actions-container_button {
    background: none;
    border: none;
    height: 24px;
    width: 24px;
}
.actions-container_button  img {
    height: 80%;
    width: 80%;
}
.actions-container_button:hover {
    filter: var(--filter-to-primary);
}
.actions-container_button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
