.custom-form {
    width: 500px;
    padding: 20px;
    position: relative;
}
.custom-form_buttons-section {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}
