.themed-button {
    border: none;
    border-radius: 14px;
    box-shadow: 0 21px 27px -10px var(--shadow-gray);
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    transition: all 0.5s;
}
.themed-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}
.themed-button > img {
    margin-right: 20px;
}
.themed-button > h3 {
    font-family: TT-Norms, sans-serif;
    font-weight: 700;
    margin-bottom: 0 !important;
    padding: 0 !important;
}
.back-button {
    z-index: 5;
    background-color: var(--back-button-bg-gray);
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100px;
    position: absolute;
    top: 15px;
    left: 0;
}
.back-button > h3 {
    font-size: 16px;
    font-family: TT-Norms-Regular, sans-serif;
    margin-bottom: 0;
    margin-left: 15px;
    opacity: 0.5;
}
.themed-button_spinner {
    width: 30px !important;
    height: 30px !important;
    border: 3px solid;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    animation: rotation 1s linear infinite;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}