.header {
    position: relative;
    height: 15%;
    max-height: 120px;
    border-bottom: solid 1px var(--light-gray);
    display: flex;
    justify-content: space-between;
}
.header_user-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 500px;
    height: 60px;
    background: var(--white);
    box-shadow: 14px 17px 40px 4px var(--shadow-gray);
    border-radius: 30px;
}
.header_user-section__info-header {
    color: var(--header-gray);
    font-size: 14px;
    align-items: center;
    margin: 0;
    font-weight: 700;
}
.header_user-section__logout-button {
    height: 34px;
    width: 34px;
    border: none;
    background: none;
}
.header_user-section__logout-button > img {
    height: 100%;
    width: 100%;
}
.header_modal__paragraph {
    font-family: TT-Norms-Regular, sans-serif;
    width: 70%;
    text-align: center;
    align-self: center;
    margin-bottom: 50px;
    white-space: pre-wrap;
}
.header_modal__buttons-section {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 50px;
}