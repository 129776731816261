.lateral-menu_link {
    text-decoration: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 15px;
    height: 60px;
    transition: background-color 0.3s;
    position: relative;
}
.lateral-menu_link:hover {
    background-color: var(--nav-gray);
}
.selected {
    background-color: var(--nav-gray);
}
.lateral-menu_link > img {
    width: 25px;
}
.lateral-menu_link > h2 {
    color: var(--white);
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 20px;
}
.lateral-menu_link > h3 {
    color: var(--primary);
    background-color: var(--primary-light-alt);
    height: 20px;
    line-height: 20px;
    width: 50px;
    border-radius: 40px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 700;
    position: absolute;
    right: -10px;
}
.lateral-menu_link__circle {
    background-color: var(--primary);
    height: 10px;
    width: 10px;
    border-radius: 10px;
    position: absolute;
    right: 8px;
    top: 8px;
}
.lateral-menu_link:hover > h2 {
    color: var(--white) !important;
}
@media (max-width: 914px) {
    .lateral-menu_link > h2 {
        display: none;
    }
}