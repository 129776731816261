.popup-container {
  width: 60%;
  position: absolute;
  bottom: 10%;
  padding: 5px 10px;
  text-align: center;
  color: white;
  border-radius: 8px;
}

.error-popup {
  background-color: palevioletred;
}

.success-popup {
  background-color: seagreen;
}