.modalHeader {
  background: #f9f9fb 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  font-family: 'TT-Norms-Black';
}
.modalBody {
  align-items: center;
  width: 100%;
  text-align: left;
  align-self: center;
  padding-left: 40px;
}
.tableModal {
  width: 100%;
  align-self: center;
  text-align: left;
}

.th {
  text-transform: capitalize;
}

.buttonResend {
  background: #00bf7133 0% 0% no-repeat padding-box;
  border: 2px solid #00bf71;
  border-radius: 31px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'TT-Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  opacity: 1;
}

.buttonRevoke {
  background: #00bf7133 0% 0% no-repeat padding-box;
  border: 2px solid #00bf71;
  border-radius: 31px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'TT Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  opacity: 1;
}
.iconClose {
  margin-right: 1%;
  width: 24px;
  height: 24px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: flex-end;
}

.button-right {
  margin-left: auto;
}

.tableModal {
  border-collapse: separate;
  border-spacing: 0 10px;
  --first-level-bg-color: #F5F8FB;
}

.tableModal > tbody > tr {
  background-color: var(--first-level-bg-color);
}

.tableModal > tbody > tr > th {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  padding: 10px;
  width: 25%;
  font-size: 0.9rem;
  font-family: TT-Norms;
  font-weight: 700;
}

.tableModal > tbody > tr > td {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  word-break: break-all;
  height: 40px;
  padding: 10px;
}

.nestedTable {
  border-radius: 6px;
  border-collapse: separate;
  margin-bottom: 10px;
  box-shadow: 0px -3px 8px 0px #35384340;
  width: 100%;
}

.nestedTableBody {

}

.nestedTable > th {
  word-break: break-all;
  padding: 10px;
  width: 25%;
  border-bottom-width: 0;
  vertical-align: middle;
}

.nestedButton {
  border: none;
  background-color: transparent;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.attributeHeader {
  font-size: 1.25rem;
  font-weight: 700;
}

.nestedHeader {
  font-size: 0.9rem;
  font-family: TT-Norms;
  font-weight: 700;
}

.detailText {
  word-break: break-all;
  height: 40px;
  padding: 10px;
}
