.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}
.input-wrapper > label {
    font-size: 16px;
    font-family: TT-Norms, sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}
.input-wrapper > p {
    font-family: TT-Norms-Regular, sans-serif;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}
.input-wrapper_input-section {
    position: relative;
}
.input-wrapper_input-section__input {
    font-size: 16px;
    font-family: TT-Norms-Regular, sans-serif;
    text-align: center;
    margin-bottom: 10px;
    width: 325px;
    height: 60px;
    border-radius: 50px;
    border: solid 1px;
    outline: none;
    color: var(--black);
}
.input-wrapper_input-section__input:read-only {
    border-radius: 14px;
    border: none;
    background-color: var(--back-button-bg-gray);
}
.input-wrapper_input-section__input::placeholder {
    opacity: 0.3;
    color: var(--black);
}
.input-wrapper_input-section__invalid-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -150px;
    height: 30px;
    width: 150px;
    top: 15px
}
.input-wrapper_input-section__invalid-section > h4 {
    font-size: 12px;
    color: var(--error-font-color);
    margin: 0 0 0 10px;
}
.select-container_dropdown-button {
    position: relative;
    margin-bottom: 10px;
    width: 325px;
    height: 60px;
    border-radius: 14px;
    border: solid 1px var(--light-gray);
    outline: none;
    background-color: var(--white);
    color: var(--black);
}
.select-container_dropdown-button > h3 {
    font-size: 16px;
    font-family: TT-Norms-Regular, sans-serif;
    text-align: center;
    margin: 0;
}
.select-container_dropdown-button > img {
    filter: brightness(0) saturate(100%) invert(50%) sepia(11%) saturate(715%) hue-rotate(176deg) brightness(96%) contrast(92%);
    transition: all 0.2s;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
}
.select-container_options-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 325px;
    gap: 2px;
    max-height: 150px;
    overflow: scroll;
    z-index: 30;
    background-color: var(--white);
}
.select-container_options-container_option {
    width: 100%;
    background-color: var(--option-gray);
    height: 48px;
    min-height: 48px;
    border-radius: 14px;
    border: none;
}
.select-container_options-container > button > h3 {
    font-size: 16px;
    font-family: TT-Norms-Regular, sans-serif;
    margin: 0;
}
.select-container_options-container > button:hover, .selected-option {
    background-color: var(--alt-light-gray);
}