.avatar-container {
    height: 41px;
    width: 41px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-container > h3 {
    margin: 0;
    font-size: 16px;
}