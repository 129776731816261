.modalHeader {
  background: #f9f9fb 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  font-family: 'TT-Norms-Black';
}
.modalBody {
  align-items: center;
  width: 100%;
  text-align: left;
  align-self: center;
  padding: 10px 40px;
}
.tableModal {
  width: 100%;
  align-self: center;
  text-align: left;
}

.th {
  text-transform: capitalize;
}

.iconClose {
  margin-right: 1%;
  width: 24px;
  height: 24px;
}


.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.button-right {
  margin-left: auto;
}
