.credentials-container {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.credentials-container_create-button-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}
.filter-list-container {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
}
.credentials-container_actions-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
.create-user-button {
    background-color: var(--primary-dark);
    border: 2px solid var(--primary);
    border-radius: 31px;
    opacity: 1;
    font-family: TT-Norms, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    color: var(--black);
    padding-left: 3%;
    padding-right: 3%;
    display: block;
    margin-left: auto;
    outline: none;
}
.filter-container {
    display: flex;
    align-items: center;
}
.custom-searchbar {
    border: none;
    height: 70px;
    width: 600px;
    border-radius: 40px;
    align-items: center;
    display: flex;
    padding: 0 20px;
    background-color: var(--input-bg-gray);
}
.custom-searchbar_image {
    width: 24px;
    height: 24px;
}
.custom-searchbar_input {
    width: 90%;
    height: 100%;
    border: none;
    color: var(--header-gray);
    font-size: 18px;
    margin-left: 10px;
    outline: none;
    background-color: var(--input-bg-gray);
}
.filter-container_button {
    border: none;
    background: none;
    padding: 0;
    margin-left: 20px;
}
.filter-container_button__image {
    height: 41px;
    width: 41px;
    transition: all .5s;
}
