.summary-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    max-height: 600px;
}
.summary-container > h2 {
    font-size: 24px;
    height: 20%;
}
.summary-container_middle-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 60%;
    overflow: scroll;
    width: 100%;
}
.summary-container_bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 20%;
}