.stepper-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    max-height: 600px;
}
.stepper-container_top-section {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 20%;
}
.stepper-container_top-section__step-tracker {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 200px;
    min-height: 100px;
}
.step-tracker_number {
    font-size: 14px;
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    margin: 0;
    font-weight: 700;
    transition: all .25s linear;
    transition-delay: 500ms;
}
.step-tracker_circle__join-line {
    height: 8px;
    width: 200px;
    z-index: -1;
    background: linear-gradient(to left, var(--alt-light-gray) 50%, var(--primary-light) 50%) right;
    background-size: 200%;
    transition: .5s linear;
    position: absolute;
    top: 12px;
    right: -100px;
}
.step-tracker_title {
    margin-top: 20px;
    font-size: 14px;
}
.stepper-container_middle-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60%;
    overflow: scroll;
    width: 100%;
}
.stepper-container_bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 20%;
}
