.did-details-container {
    width: 500px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}
.did-details-container > p {
    width: 70%;
}
.did-details-container_did-document-section {
    width: 100%;
    height: 100%;
    position: relative;
}
.did-details-container_did-document-section-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
}
.did-details-container_did-document-section__button {
    z-index: 20;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.did-details-container_did-document-section__button > img {
    height: 16px;
}