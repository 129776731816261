.lateral-menu-container {
    background-color: var(--dark-blue);
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: var(--default-transition);
    position: relative;
}
.lateral-menu_logo-wrapper {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lateral-menu_logo {
    height: 50px;
}
.lateral-menu_navigator {
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.lateral-menu_expandable-button {
    width: 30px;
    border: none;
    color: var(--white);
    position: absolute;
    height: 60px;
    bottom: 0;
    right: 0;
    background-color: var(--nav-gray);
    border-radius: 10px 0 0 10px;
}
.lateral-menu_expandable-button > img {
    transition: var(--default-transition);
}
@media (max-width: 914px) {
    .lateral-menu-container {
        width: 100px;
    }
    .lateral-menu_expandable-button {
        display: none;
    }
}