.tableHeader {
  background: #F9F9FB 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  font-family: 'TT-Norms-Black';
}
.tableFixed{
  width: 150px;
}
.divFixed{
  width: 150px;
}

.divFixed span{
  display:inline-block;
  width:100%;
  overflow: hidden; 
  text-overflow: ellipsis;  
}

.MuiPaper-elevation2 > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters:nth-child(1) {
  display: none;
}

.MuiPaper-elevation2 {
  left: 2px;
  border-radius: none;
}
