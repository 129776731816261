.breadcrumb-wrapper {
    display: flex;
}
.breadcrumb-section {
    position: absolute;
    bottom: 0;
    display: flex;
}
.breadcrumb-section_link {
    text-decoration: none;
}
.breadcrumb-section_link > h3:hover {
    font-weight: 900;
}
.breadcrumb-section_link > h3, .breadcrumb-section_separator {
    color: var(--header-gray);
    font-size: 14px;
}
.breadcrumb-section_separator {
    margin: 0 2px
}