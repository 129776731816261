.language-selector {
    height: 45px;
    width: 75px;
    border-radius: 40px;
    background-color: var(--input-bg-gray);
    position: relative;
    border: none;
}
.language-selector input {
    background-color: transparent;
    color: var(--header-gray);
    height: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-family: TT-Norms, sans-serif;
    font-weight: 700;
    border: none;
    font-size: 14px;
}
.language-selector_arrow {
    filter: brightness(0) saturate(100%) invert(50%) sepia(11%) saturate(715%) hue-rotate(176deg) brightness(96%) contrast(92%);
    transition: all 0.2s;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
}
.options-container {
    background-color: var(--input-bg-gray);
    width: 75px;
    position: absolute;
    z-index: 5;
    border-radius: 22px;
    margin-top: 5px;
}
.options-container button {
    font-weight: 500;
    font-family: TT-Norms, sans-serif;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    height: 45px;
    width: 100%;
    border-radius: 45px;
    background-color: transparent;
    color: var(--header-gray);
    border: none;
}
.options-container button:not(:last-child) {
    margin-bottom: 3px;
}
.options-container button:hover {
    background-color: var(--primary-light-alt);
    color: var(--primary-font-color);
}

@media (max-width: 768px) {
    .language-selector {
        height: 30px;
        width: 50px;
    }
    .language-selector input {
        font-size: 12px;
    }
    .language-selector_arrow {
        right: 6px;
        height: 10px;
    }
}