.modalHeader {
  background: #f9f9fb 0% 0% no-repeat padding-box;
  border-radius: 6px 6px 0px 0px;
  opacity: 1;
  font-family: 'TT-Norms-Black';
}
.modalBody {
  align-items: center;
  width: 100%;
  text-align: left;
  align-self: center;
  padding-left: 40px;
}
.tableModal {
  width: 100%;
  align-self: center;
  text-align: left;
}

.th {
  text-transform: capitalize;
}

.buttonResendRight {
  background: #00bf7133 0% 0% no-repeat padding-box;
  border: 2px solid #00bf71;
  border-radius: 31px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'TT-Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  opacity: 1;
  margin-left: 80%;
}

.buttonRevoke,
.buttonRevoke {
  background: #00bf7133 0% 0% no-repeat padding-box;
  border: 2px solid #00bf71;
  border-radius: 31px;
  padding-left: 5%;
  padding-right: 5%;
  font-family: 'TT-Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  opacity: 1;
}
.iconClose {
  margin-right: 1%;
  width: 24px;
  height: 24px;
}
.buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
}

.buttons-container .unsigned-warning {
  margin-bottom: 0;
  color: red;
  font-weight: bold;
}

.button-right {
  margin-left: auto;
}

.devices-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.device-container {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  border: 2px solid #00bf71;
  padding: 5px 20px;
  border-radius: 4px;
}

.device-container:hover,
.selected-device {
  border: 2px solid #00bf71;
  background-color: #00bf71;
  color: white;
}

.device-container img {
  width: 35px;
  height: 35px;
}

.device-container .device-name {
  font-weight: bold;
  font-family: 24px;
  margin-bottom: 0px;
}

.device-container .device-description {
  font-family: 20px;
  margin: 0;
}

.pagination-container {
  margin-top: 15px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hidden {
  visibility: hidden;
}

.pagination-container li {
  background-color: #151a35;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  font-family: TT-Norms;
  font-weight: bold;
}
