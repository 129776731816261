.create-success-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.create-success-container_description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create-success-container_description-container > p {
    white-space: pre-wrap;
    line-height: 30px;
    margin-bottom: 40px;
    width: 70%;
}