.copy-tooltip-button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    background-color: black;
    transition: all 0.5s;
}
.copy-tooltip-button > img {
    height: 16px;
}