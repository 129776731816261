@font-face {
  font-family: 'TT-Norms';
  src: url(assets/fonts/TTNorms-Medium_variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Regular';
  src: url(assets/fonts/TTNorms-Regular-variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Black';
  src: url(assets/fonts/TTNorms-ExtraBold-variable.otf);
}
:root {
  --primary: #00bf71;
  --primary-light: #C9FBD5;
  --primary-light-alt: #D3F2E3;
  --primary-font-color: #02934E;
  --primary-dark: #00bf7133;
  --error-bg: #FDE0D0;
  --error-font-color: #FF0000;
  --gold: #E8B923;
  --black: #151A35;
  --dark-blue: #151935;
  --header-gray: #718096;
  --nav-gray: #5E6272;
  --shadow-gray: #7090b033;
  --gray: #8a8d9a;
  --light-gray: #D2D2D2;
  --border-gray: #E0E5F2;
  --input-bg-gray: #F4F7FB;
  --button-bg-gray: #E1E1E1;
  --back-button-bg-gray: #F2F2F2;
  --alt-light-gray: #E5E6E6;
  --option-gray: #F7F7F7;
  --white: #ffffff;
  --modal-bg: #00000066;
  --default-transition: all 0.5s;
  --filter-to-primary: invert(56%) sepia(67%) saturate(1320%) hue-rotate(113deg) brightness(86%) contrast(109%);
}
* {
  margin: 0;
  padding: 0;
  font-family: TT-Norms, sans-serif;
}
body {
  font-family:  TT-Norms-Regular ,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: TT-Norms-Black, sans-serif;
  font-size: 35px;
  color: var(--black);
}
p {
  margin: 0;
}
button {
  font-size: 18px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img, svg {
  vertical-align: baseline;
}