.banner-container {
    position: absolute;
    top: 120px;
    left: 50%;
    min-width: 440px;
    height: 50px;
    border-radius: 10px;
    transform: translate(-50%);
    transition: opacity 1s;
    padding: 0 10px;
}
.banner-container > p {
    height: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    line-height: 50px;
    transition: all 1s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}