.modal-background {
    background-color: var(--modal-bg);
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
}
.modal-container {
    box-shadow: 14px 27px 45px 4px #00000033;
    background-color: var(--white);
    position: relative;
    min-width: 560px;
    max-height: 510px;
    border-radius: 14px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: scroll;
}
.modal-container > h2 {
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}
.modal-container_close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    height: 24px;
    display: flex;
    align-items: center;
}
.modal-container_close-button > img {
    filter: var(--filter-to-primary);
}