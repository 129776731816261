.list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.list-header {
    font-family: TT-Norms, sans-serif;
    color: var(--header-gray);
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
}
.list {
    padding: 0;
    overflow: scroll;
}
.list-empty-header {
    font-family: TT-Norms-Regular, sans-serif;
    color: var(--header-gray);
    font-weight: 500;
    margin-top: 50px;
    font-size: 16px;
    text-align: center;
    transition: var(--default-transition);
}
.list-loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}